import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CotizacionResponse, DataResponseCotizacion } from '@app/views/deals/interfaces/deal.interface';
import { DealsService } from '@app/views/deals/services/deals.service';

@Component({
  selector: 'app-plantilla-cotizacion',
  templateUrl: './plantilla-cotizacion.component.html',
  styleUrls: ['./plantilla-cotizacion.component.scss']
})
export class PlantillaCotizacionComponent implements OnInit {
  @ViewChild('htmlData') htmlData!: ElementRef;
  public idCotizacion!: string;
  public loadingCotizacion = true;
  public discount = false;
  public cotizacion!: DataResponseCotizacion;
  public dataSource = new MatTableDataSource<CotizacionResponse>([]);
  public totalPrice = "$";
  public displayedCols = [
    'documentType',
    'amount',
  ];

  constructor(private router: ActivatedRoute, private dealSvc: DealsService) {
    this.router.params.subscribe((params) => {
      this.idCotizacion = params['id'];
    });
  }

  ngOnInit(): void {
    this.loadCotizacion();
  }
  
  private loadCotizacion(): void {
    this.getCotizacion(this.idCotizacion);
  }
  
  public getCotizacion(idCotizacion: string): void {
    this.dealSvc.getCotizacionId(idCotizacion).subscribe({
      next: (res) => {
        this.cotizacion = res;
        this.dataSource.data = res.items;
        this.loadingCotizacion = false;
        this.getTotalCost();
        this.discountDisplay();
        this.getHtmlTemplate();
      },
      error: (err) => {
        console.error('Error al obtener la cotización:', err);
        this.loadingCotizacion = false;
      }
    });
  }
  

  public descripcionProducto(
    producto: string,
    finalidad: string,
    fechaInicio: Date | string,
    fechaTermino: Date | string,
    monto: number,
    currency: string
  ): string {
    const parseFecha = (fecha: Date | string): Date =>
      fecha instanceof Date ? fecha : new Date(fecha);
  
    const formatoFecha = (fecha: Date): string => {
      const day = fecha.getDate().toString().padStart(2, '0');
      const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const year = fecha.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const fechaInicioFormatted = formatoFecha(parseFecha(fechaInicio));
    const fechaTerminoFormatted = formatoFecha(parseFecha(fechaTermino));
    const montoFormatted = this.formatearMonto(monto, currency);
  
    return `${producto} para ${finalidad} con Fecha Inicio el ${fechaInicioFormatted} y Término el ${fechaTerminoFormatted} por un monto de ${currency} ${montoFormatted}`;
  }
  

  public getTotalCost(): string {
    if (this.cotizacion != null && this.cotizacion.commission != null) {
      const esMonedaPesos = this.cotizacion.currency === '$';
      const formattedCommission = esMonedaPesos
        ? this.agregarSeparadorMiles(Math.round(this.cotizacion.commission).toString()) 
        : this.cotizacion.commission.toLocaleString('es-CL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).replace('.', ','); 
  
      return `${this.cotizacion.currency} ${formattedCommission}`;
    }
    return '';
  }

  private agregarSeparadorMiles(valor: string): string {
    return valor.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); 
  }

  private formatearMonto(monto: number, currency: string): string {
    const esMonedaPesos = currency === '$';
    if (esMonedaPesos) {
      return this.agregarSeparadorMiles(Math.round(monto).toString());
    } else {
      return monto.toLocaleString('es-CL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).replace('.', ',');
    }
  }
  

  public getHtmlTemplate() {
    const htmlString = this.htmlData.nativeElement.innerHTML;
    console.log(htmlString);
    return htmlString;
  }

  public discountDisplay() {
    this.discount = this.dataSource.data.some(item => item.discount > 0);
  }
  

  public getCostProducts(): string {
    let total=0;
    for(let item of this.dataSource.data) {
      total = total + item.price;
    }
    let totaFormat = this.formatearMonto(total, this.dataSource.data[0].itemPriceCurrency);
    return `${this.dataSource.data[0].itemPriceCurrency} ${totaFormat}`;
  }

  public getDiscontProducts(): string {
    let total=0;
    for(let item of this.dataSource.data) {
      total = total + item.discount;
    }
    let totaFormat = this.formatearMonto(total, this.dataSource.data[0].itemPriceCurrency);
    return `${this.dataSource.data[0].itemPriceCurrency} ${totaFormat}`;
  }

}