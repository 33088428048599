import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Corredoras, CotizacionRequest, CotizacionResponse, CotizacionResponseStatus, Currency, DataResponseCotizacion, Deal, DictionaryEconomy, DiscountRequest, Finalidad, PageDataResponseCotizacionDeal, PageRequestCotizacionDeal, RequestDeleteItem, ResponseLicitacionData, SendCotizacion, TypeProduct, UpdateSimulationItemRequest, WarrantieQuote } from '../interfaces/deal.interface';
import { DealDetail } from '../interfaces/details.interface';
import {
  ActionsOptions,
  AllActionsOptions,
  DealsActions,
  PendingData,
  RegisterDealAction,
  RegisterDealActionResponse,
} from '../interfaces/dealActions.interface';
import { LostDealRequest } from '../interfaces/dealP.interface';
import { DealG } from '../interfaces/dealG.interface';
import { DealProgress } from '../interfaces/dealProgress.interface';
import {
  DataDone,
  DealActivity,
  DoneActivity,
} from '../interfaces/dealActivity.interface';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/models/Response';
import { Stage } from 'src/app/models/FunnelModels';
import {
  UpdateDealRequest,
  UpdateDealStageRequest,
} from 'src/app/models/Deals/UpdateDealDto';
import { DealByCompanyDTO } from './../../../models/Deals/DealByCompanyDTO';
import { StageSummaryDTO } from './../../../models/Deals/StageSummaryDTO';
import {
  DealItemBoardDTO,
  NewDealForm,
} from 'src/app/models/OpportunityModels';
import { UpdateDealStageResponse } from 'src/app/models/Deals/UpdateDealStageResponse';
import { InsertDealResponseDTO } from './../../../models/Deals/InsertDealFeatureDTO';
import {
  UpdateDealPriorityRequestDTO,
  UpdateDealPriorityResponseDTO,
} from './../../../models/Deals/UpdateDealPriorityFeature';
import {
  UpdateDealOwnerRequestDTO,
  UpdateDealOwnerResponseDTO,
} from './../../../models/Deals/UpdateDealOwnerFeature';
import {
  InsertReferredDealRequestDTO,
  InsertReferredDealResponseDTO,
} from '@app/models/Deals/InsertReferredDealFeature';
import {
  ReopenDealRequestDTO,
  ReopenDealResponseDTO,
} from '@app/models/Deals/ReopenDealFeature';
import { AuthService } from '@app/services/auth/auth.service';
import { ResolutionDealActionResponseDTO } from '@app/models/DealActions/DealActionRequests';
import { DealActionToResolute } from '@app/models/DealActions/DealActionToResolute';

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  url = `${environment.apiUrl}/Deals`;
  urlApi = `${environment.apiUrl}/api`;
  urlTender = `${environment.apiUrl}/Tenders`;
  constructor(private http: HttpClient, private authService: AuthService) {}

  getDeals(id: string): Observable<Deal> {
    let url = this.url + `/GetDeals/${id}`;
    return this.http.get<Deal>(url);
  }

  getDetails(id: string): Observable<DealDetail> {
    let url = this.url + `/GetDetails/${id}`;
    return this.http.get<DealDetail>(url);
  }

  getActionsDetail(): Observable<ActionsOptions[]> {
    let url = this.url + `/actionOptions`;
    return this.http.get<ActionsOptions[]>(url);
  }

  getAllActionOptions(): Observable<AllActionsOptions[]> {
    let url = this.url + `/allActionOptions`;
    return this.http.get<AllActionsOptions[]>(url);
  }

  getDealsDetailsActions(values: number): Observable<DealsActions[]> {
    let url = this.url + `/actions?status=${values}`;
    return this.http.get<DealsActions[]>(url);
  }

  dealsPendingData(
    form: ResolutionDealActionResponseDTO
  ): Observable<ResolutionDealActionResponseDTO> {
    let url = this.url + '/action/pendingData';
    return this.http.put<ResolutionDealActionResponseDTO>(url, form);
  }

  dealsResolvedPendingData(
    form: DealActionToResolute
  ): Observable<DealActionToResolute> {
    let url = this.url + '/action/resolutePendingData';
    return this.http.put<DealActionToResolute>(url, form);
  }

  editDetails(form: UpdateDealRequest): Observable<DealDetail> {
    let url = this.url + '/EditDetails';
    return this.http.post<DealDetail>(url, form);
  }

  dealStatusP(form: LostDealRequest): Observable<LostDealRequest> {
    let url = this.url + '/DealStatusP';
    return this.http.post<LostDealRequest>(url, form);
  }

  dealStatusG(form: DealG): Observable<DealG> {
    let url = this.url + '/DealStatusG';
    return this.http.post<DealG>(url, form);
  }

  dealActivity(form: DealActivity): Observable<DealActivity> {
    let url = this.url + '/InsertActivityDeal';
    return this.http.post<DealActivity>(url, form);
  }

  updateDealOwner(
    form: UpdateDealOwnerRequestDTO
  ): Observable<ApiResponse<UpdateDealOwnerResponseDTO>> {
    let url = this.url + '/owner';
    return this.http.put<ApiResponse<UpdateDealOwnerResponseDTO>>(url, form);
  }

  dealProgress(id: string): Observable<Stage[]> {
    let url = this.url + `/GetProgressDeal/${id}`;
    return this.http.get<Stage[]>(url);
  }

  getDealByCompany(companyRut: string): Observable<Array<DealByCompanyDTO>> {
    let url = this.url + `/company/${companyRut}`;
    return this.http.get<Array<DealByCompanyDTO>>(url);
  }

  getLostReasons(): Observable<Array<string>> {
    const urlEndpoint = this.url + `/lostReasons`;
    return this.http.get<Array<string>>(urlEndpoint);
  }

  getStagesWithSummary(
    executiveOrGroup: string,
    funnelId: string
  ): Observable<Array<StageSummaryDTO>> {
    const endpoint =
      this.url +
      `/board/stagesSummary?executive=${executiveOrGroup}&funnel=${funnelId}`;
    return this.http.get<Array<StageSummaryDTO>>(endpoint);
  }

  getDealsForBoard(
    executiveOrGroup: string,
    funnel: string,
    status: string
  ): Observable<Array<DealItemBoardDTO>> {
    const endpoint: string =
      this.url +
      `?ExecutiveOrGroup=${executiveOrGroup}&Funnel=${funnel}&Status=${status}`;
    return this.http.get<Array<DealItemBoardDTO>>(endpoint);
  }

  updateDealStage(
    updateRequest: UpdateDealStageRequest
  ): Observable<ApiResponse<UpdateDealStageResponse>> {
    const endpoint = this.url + '/stage';
    return this.http.put<ApiResponse<UpdateDealStageResponse>>(
      endpoint,
      updateRequest
    );
  }

  insertDeal(
    request: NewDealForm
  ): Observable<ApiResponse<InsertDealResponseDTO>> {
    const endpoint = this.url;
    return this.http
      .post<ApiResponse<InsertDealResponseDTO>>(endpoint, request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () => error.error as ApiResponse<InsertDealResponseDTO>
          );
        })
      );
  }

  updateDealPriority(
    request: UpdateDealPriorityRequestDTO
  ): Observable<UpdateDealPriorityResponseDTO> {
    const endpoint = this.url + '/priority';
    return this.http.put<UpdateDealPriorityResponseDTO>(endpoint, request);
  }

  insertReferredDeal(
    request: InsertReferredDealRequestDTO
  ): Observable<ApiResponse<InsertReferredDealResponseDTO>> {
    const endpoint = this.url + '/referred';
    return this.http
      .post<ApiResponse<InsertReferredDealResponseDTO>>(endpoint, request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () => error.error as ApiResponse<InsertReferredDealResponseDTO>
          );
        })
      );
  }

  reopenDeal(
    request: ReopenDealRequestDTO
  ): Observable<ApiResponse<ReopenDealResponseDTO>> {
    const endpoint = this.url + '/reopen';
    return this.http.put<ApiResponse<ReopenDealResponseDTO>>(endpoint, request);
  }

  registerDealActions = (
    dealId: string,
    actions: Array<RegisterDealAction>
  ) => {
    const endpoint = `${this.url}/${dealId}/action`;
    return this.http.post<ApiResponse<RegisterDealActionResponse>>(
      endpoint,
      actions
    );
  };

  doneActivity(dealId: string, activityId: string): Observable<DoneActivity> {
    let done2!: DoneActivity;
    const endpoint = `${this.url}/${dealId}/activity/${activityId}/done`;
    return this.http.put<DoneActivity>(endpoint, done2);
  }

  abandonedActivity(dealId: string, activityId: string): Observable<DoneActivity> {
    let done2!: DoneActivity;
    const endpoint = `${this.url}/${dealId}/activity/${activityId}/abandoned`;
    return this.http.put<DoneActivity>(endpoint, done2);
  }

  registerCotizacion(cotizacion: CotizacionRequest, dealId: string): Observable<CotizacionResponseStatus> {
    let url = this.url + `/${dealId}/simulations`;
    return this.http.post<CotizacionResponseStatus>(url, cotizacion);
  }

  confirmPrice(cotizacion: SendCotizacion[], simulationId: string): Observable<CotizacionResponseStatus> {
    let url = this.url + `/simulations/${simulationId}/ConfirmPrice`;
    return this.http.post<CotizacionResponseStatus>(url, cotizacion);
  }

  discountCotizacion(request: DiscountRequest, simulationId: string): Observable<CotizacionResponseStatus> {
    let url = this.url + `/simulations/${simulationId}/item/discount`;
    return this.http.post<CotizacionResponseStatus>(url, request);
  }

  getCotizacionDeals(idDeal: string, request: PageRequestCotizacionDeal): Observable<PageDataResponseCotizacionDeal> {
    let params = new HttpParams()
    .set('page', request.page || 1)
    .set('pageSize', request.pageSize || 5);
    let url = this.url + `/${idDeal}/simulations`;
    return this.http.get<PageDataResponseCotizacionDeal>(url, {params: params});
  }

  getCotizacionId(cotizacionId: string): Observable<DataResponseCotizacion> {
    let url = this.url + `/simulations/${cotizacionId}`;
    return this.http.get<DataResponseCotizacion>(url);
  }

  getIndicadoresEconomicos(): Observable<DictionaryEconomy> {
    let url = this.urlApi + `/Indicators`;
    return this.http.get<DictionaryEconomy>(url);
  }

  getProductTypes(): Observable<TypeProduct[]> {
    let url = this.url + `/simulations/productTypes`;
    return this.http.get<TypeProduct[]>(url);
  }

  getTargets(): Observable<Finalidad[]> {
    let url = this.url + `/simulations/targets`;
    return this.http.get<Finalidad[]>(url);
  }

  getCurrencies(): Observable<Currency[]> {
    let url = this.url + `/simulations/currencies`;
    return this.http.get<Currency[]>(url);
  }

  deleteItemCotizacion(data: RequestDeleteItem): Observable<ApiResponse<DataResponseCotizacion | null>> {
    let url = this.url + `/simulations/${data.simulationId}/item/${data.itemId}`;
    return this.http.delete<ApiResponse<DataResponseCotizacion | null>>(url);
  }

  newProductCotizacion(simulationId: string, data: WarrantieQuote): Observable<CotizacionResponseStatus> {
    let url = this.url + `/simulations/${simulationId}/items`;
    return this.http.post<CotizacionResponseStatus>(url, data);
  }

  getDataLicitacion(codeLicitacion: string, rutAdjudicado: string): Observable<ResponseLicitacionData[]> {
    let url = this.urlTender + `/${codeLicitacion}/${rutAdjudicado}/RequiredGuarantees`;
    return this.http.get<ResponseLicitacionData[]>(url);
  }

  sendSimulation(emails: string[], idCotizacion: string): Observable<CotizacionResponseStatus> {
    let url = this.url + `/simulations/${idCotizacion}/Send`;
    return this.http.post<CotizacionResponseStatus>(url, emails);
  }

  deleteQuote(id: string): Observable<ApiResponse<boolean>> {
    const url = `${this.url}/Simulations/${id}`;
    return this.http.delete<ApiResponse<boolean>>(url);
  }

  updateSalesQuoteItemProduct(id: string, itemId: string, update: UpdateSimulationItemRequest): Observable<ApiResponse<CotizacionResponse>> {
    const url = `${this.url}/simulations/${id}/items/${itemId}`;
    return this.http.put<ApiResponse<CotizacionResponse>>(url, update);
  }

  /**
   * Emitir producto cotizado
   * @param simulationId Id de cotizacion simulada
   * @param itemId Id de item cotizado e emitir
   * @param updateValues Asignar valores faltantes a modificar
   * @returns Respuesta con mensaje y cotizacion actualizada
   */
  issueSalesQuoteItem(simulationId: string, itemId: string, updateValues: UpdateSimulationItemRequest): Observable<ApiResponse<DataResponseCotizacion>>{
    const url = `${this.url}/simulations/${simulationId}/items/${itemId}/issue`;
    return this.http.post<ApiResponse<DataResponseCotizacion>>(url, updateValues);
  }

    /**
   * Obtiene las corredoras
   * @returns Respuesta con objeto List Corredoras
   */
  getCorredoras(): Observable<Corredoras[]> {
    let url = this.urlApi + `/Settings/insurance-brokers`;
    return this.http.get<Corredoras[]>(url);
  }

}